import React from "react";
import Loader from "react-loader-spinner";

export default function Spinner() {
    return (
        <div className="display-flex-center">
            <Loader type="TailSpin" color="#3aaa35" />{" "}
        </div>
    );
}
