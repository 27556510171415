import { useState, useEffect } from "react";
import { useMyContext } from "../../../contexts/StateHolder";
import { countries } from "../../../scripts/countries";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../Shared/Hooks/form-hook";
import { VALIDATOR_EMAIL, VALIDATOR_NONE } from "../../../Shared/Validation/Validator";
import { Input } from "../../../Shared/Input/Input";
import { updateUser, getUser } from "../../../scripts/dataHandlers";
import { useCookies } from "react-cookie";
import * as classes from "../../../Shared/Input/Input.module.css";
import ProfileHeader from "../ProfileHeader";
import { toast, ToastContainer } from "react-toastify";
import Loader from "react-loader-spinner";

const UserDetails = (props) => {
    // Bring stateholders from context
    const { user, setUser, organizationId, key, language } = useMyContext();

    console.log(user, "useer1");

    // Holds inform message for form
    const [formMessage, setFormMessage] = useState("");

    const [cookies] = useCookies("");

    // Setup translate function
    const { t } = useTranslation();

    // we need to import UseForm hook and pass the initial inputs and initialFormValidity to userform
    const [state, InputHandler] = useForm(
        {
            FIRSTNAME: {
                value: "",
                isValid: true,
            },
            LASTNAME: {
                value: "",
                isValid: true,
            },
            EMAIL: {
                value: "",
                isValid: true,
            },
            PHONE: {
                value: "",
                isValid: true,
            },
            ORGANIZATIONNAME: {
                value: "",
                isValid: true,
            },
            YTUNNUS: {
                value: "",
                isValid: true,
            },
            CITY: {
                value: "",
                isValid: true,
            },
            COUNTRY: {
                value: "",
                isValid: true,
            },
            ADDRESS: {
                value: "",
                isValid: true,
            },
            POSTALCODE: {
                value: "",
                isValid: true,
            },
            DATEOFBIRTH: {
                value: "",
                isValid: true,
            },
        },
        true
        // the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
    );

    // Holder for profileData
    const [profileData, setProfileData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        city: "",
        country: "",
        countryId: "",
        address: "",
        postalCode: "",
        yTunnus: "",
        organizationName: "",
        dateOfBirth: "",
        regionId: "",
    });

    // Fetch userdata from API and set it to profileData
    useEffect(() => {
        async function getUserData() {
            try {
                const response = await getUser(cookies?.userData?.userToken, organizationId);
                console.log(response);

                const newData = {
                    firstName:
                        response.data.firstName !== "x" && response.data.firstName !== "Sub" && response.data.firstName !== "-" ? response.data.firstName : "",
                    lastName:
                        response.data.lastName !== "x" && response.data.lastName !== "Sub" && response.data.lastName !== "-" ? response.data.lastName : "",
                    phone: response.data.phone !== "0" && response.data.phone !== "-" ? response.data.phone : "",
                    email: response.data.emailAddress,
                    city: response.data.city !== "-" ? response.data.city : "",
                    country: response.data.country !== "x" ? response.data.country : "",
                    countryId: response.data.countryId !== "x" ? response.data.countryId : "",
                    yTunnus:
                        response.data?.userSettings?.items?.find((item) => item.name === "yTunnus") &&
                        response.data?.userSettings?.items?.find((item) => item.name === "yTunnus").value !== "x"
                            ? response.data?.userSettings?.items?.find((item) => item.name === "yTunnus").value
                            : "",
                    organizationName:
                        response.data?.userSettings?.items?.find((item) => item.name === "organizationName") &&
                        response.data?.userSettings?.items?.find((item) => item.name === "organizationName").value !== "x"
                            ? response.data?.userSettings?.items?.find((item) => item.name === "organizationName").value
                            : "",
                    address: response.data.address !== "-" ? response.data.address : "",
                    postalCode: response.data.postalCode !== "0" ? response.data.postalCode : "0",
                    regionId: response.data.regionId !== "-" ? response.data.regionId : "",
                    dateOfBirth: response.data?.dateOfBirth && response.data?.dateOfBirth !== "x" ? response.data.dateOfBirth : Date(),
                };

                console.log(newData);
                setProfileData({ ...newData });

                // Set buyerProducts into contexts, so subscription tab can show data
                user.buyerProducts = response.data.buyerProducts;
                setUser({ ...user });
            } catch (err) {
                console.log(err);
            }
        }

        if (user.userId !== 0) {
            getUserData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, user?.userId]);
    console.log("user", user);
    const formSubmitHandler = async (e) => {
        e.preventDefault();

        console.log(state.inputs.DATEOFBIRTH.value);

        try {
            // Update user data
            const response = await updateUser(
                user.userId,
                cookies?.userData?.userToken,
                organizationId,
                key,
                state.inputs.FIRSTNAME.value ? state.inputs.FIRSTNAME.value : "-",
                state.inputs.LASTNAME.value ? state.inputs.LASTNAME.value : "-",
                state.inputs.PHONE.value ? state.inputs.PHONE.value : "-",
                countries.find((country) => country.name === state.inputs.COUNTRY.value).id,
                profileData.regionId,
                state.inputs.CITY.value ? state.inputs.CITY.value : "-",
                state.inputs.POSTALCODE.value ? state.inputs.POSTALCODE.value : "-",
                state.inputs.EMAIL.value,
                state.inputs.ORGANIZATIONNAME.value,
                state.inputs.YTUNNUS.value,
                state.inputs.DATEOFBIRTH.value,
                state.inputs.ADDRESS.value
            );

            response?.data?.status === "ok"
                ? toast.success(t("userDetails.profileUpdated"), {
                      autoClose: 10000,
                      closeOnClick: true,
                      draggable: true,

                      theme: "dark",
                  })
                : response?.data?.message
                ? toast.error(response?.data?.message, {
                      autoClose: 10000,
                      closeOnClick: true,
                      draggable: true,

                      theme: "dark",
                  })
                : toast.error(t("userDetails.somethingWrongTryAgain"), {
                      autoClose: 10000,
                      closeOnClick: true,
                      draggable: true,

                      theme: "dark",
                  });
        } catch (err) {
            console.log(err);
            setFormMessage(t("userDetails.somethingWrongTryAgain"));
        }
    };

    // If email (required value) is there and userId is set, render input fields with initial values from profileData
    if (profileData.email !== "" && user.userId !== 0) {
        return (
            <>
                <ToastContainer />
                <div className={classes.formProfilePrimary}>
                    <div className={classes.formSecondary}>
                        <form className={classes.formBlog} onSubmit={formSubmitHandler}>
                            <ProfileHeader showSubscriptions={props.showSubscriptions} />

                            {props.fields.email ? (
                                <Input
                                    id="EMAIL"
                                    label="EMAIL"
                                    placeholder={t("userDetails.inputPlaceholderEmail")}
                                    type="text"
                                    element="input"
                                    validators={[VALIDATOR_EMAIL()]}
                                    errorText={t("userDetails.incorrectEmailAddress")}
                                    onInput={InputHandler}
                                    iconName="envelope"
                                    initialValid={true}
                                    initialValue={profileData.email}
                                    disabled={true}
                                    // style={{ margin: '15px auto' }}
                                />
                            ) : null}

                            {props.fields.firstName ? (
                                <Input
                                    id="FIRSTNAME"
                                    label="FIRST NAME"
                                    placeholder={t("userDetails.inputPlaceholderFirstName")}
                                    type="text"
                                    element="input"
                                    validators={[VALIDATOR_NONE()]}
                                    errorText={t("userDetails.askForFirstName")}
                                    onInput={InputHandler}
                                    iconName="user"
                                    initialValid={true}
                                    initialValue={profileData.firstName}
                                    // style={{ margin: '15px auto' }}
                                />
                            ) : null}

                            {props.fields.lastName ? (
                                <Input
                                    id="LASTNAME"
                                    label="LAST NAME"
                                    placeholder={t("userDetails.inputPlaceholderLastName")}
                                    type="text"
                                    element="input"
                                    validators={[VALIDATOR_NONE()]}
                                    errorText={t("userDetails.askForLastName")}
                                    onInput={InputHandler}
                                    iconName="user"
                                    initialValid={true}
                                    initialValue={profileData.lastName}
                                    // style={{ margin: '15px auto' }}
                                />
                            ) : null}

                            {props.fields.address ? (
                                <Input
                                    id="ADDRESS"
                                    label="ADDRESS"
                                    placeholder={t("userDetails.inputPlaceholderAddress")}
                                    type="text"
                                    element="input"
                                    validators={[VALIDATOR_NONE()]}
                                    errorText={t("userDetails.askForAddress")}
                                    onInput={InputHandler}
                                    iconName="map-pin"
                                    initialValid={true}
                                    initialValue={profileData.address}
                                    // style={{ margin: '15px auto' }}
                                />
                            ) : null}

                            {props.fields.postalCode ? (
                                <Input
                                    id="POSTALCODE"
                                    label="POSTALCODE"
                                    placeholder={t("userDetails.inputPlaceholderPostalCode")}
                                    type="text"
                                    element="input"
                                    validators={[VALIDATOR_NONE()]}
                                    errorText={t("userDetails.askForPostalCode")}
                                    onInput={InputHandler}
                                    iconName="map-marked"
                                    initialValid={true}
                                    initialValue={profileData.postalCode}
                                    // style={{ margin: '15px auto' }}
                                />
                            ) : null}

                            {props.fields.city ? (
                                <Input
                                    id="CITY"
                                    label="CITY"
                                    placeholder={t("userDetails.inputPlaceholderCity")}
                                    type="text"
                                    element="input"
                                    validators={[VALIDATOR_NONE()]}
                                    errorText={t("userDetails.askForCity")}
                                    onInput={InputHandler}
                                    iconName="city"
                                    initialValid={true}
                                    initialValue={profileData.city}
                                    // style={{ margin: '15px auto' }}
                                />
                            ) : null}

                            {props.fields.country ? (
                                <Input
                                    id="COUNTRY"
                                    label="COUNTRY"
                                    placeholder={t("userDetails.inputPlaceholderCountry")}
                                    type="text"
                                    selectData={countries.map((country) => country.name)}
                                    element="select"
                                    validators={[VALIDATOR_NONE()]}
                                    errorText={t("userDetails.askForCountry")}
                                    onInput={InputHandler}
                                    iconName={["fas", "globe-europe"]}
                                    initialValid={true}
                                    initialValue={profileData.country}
                                    // style={{ margin: '15px auto' }}
                                />
                            ) : null}

                            {props.fields.phone ? (
                                <Input
                                    id="PHONE"
                                    label="PHONE"
                                    placeholder={t("userDetails.inputPlaceholderPhone")}
                                    type="text"
                                    element="input"
                                    validators={[VALIDATOR_NONE()]}
                                    errorText={t("userDetails.askForPhone")}
                                    onInput={InputHandler}
                                    iconName="phone"
                                    initialValid={true}
                                    initialValue={profileData.phone}
                                    // style={{ margin: '15px auto' }}
                                />
                            ) : null}

                            {props.fields.dateOfBirth ? (
                                <Input
                                    id="DATEOFBIRTH"
                                    label="DATEOFBIRTH"
                                    placeholder={t("userDetails.inputPlaceholderDateOfBirth")}
                                    type="text"
                                    element="dateOfBirth"
                                    validators={[VALIDATOR_NONE()]}
                                    errorText={t("userDetails.askForDateOfBirth")}
                                    onInput={InputHandler}
                                    iconName="birthday-cake"
                                    initialValid={true}
                                    initialValue={profileData.dateOfBirth}
                                />
                            ) : null}

                            {props.fields.yTunnus ? (
                                <Input
                                    id="YTUNNUS"
                                    label="YTUNNUS"
                                    placeholder={t("userDetails.inputPlaceholderYtunnus")}
                                    type="text"
                                    element="input"
                                    validators={[VALIDATOR_NONE()]}
                                    errorText={t("userDetails.askForYtunnus")}
                                    onInput={InputHandler}
                                    iconName="certificate"
                                    initialValid={true}
                                    initialValue={profileData.yTunnus}
                                    // style={{ margin: '15px auto' }}
                                />
                            ) : null}

                            {props.fields.organizationName ? (
                                <Input
                                    id="ORGANIZATIONNAME"
                                    label="ORGANIZATIONNAME"
                                    placeholder={t("userDetails.inputPlaceholderOrganizationName")}
                                    type="text"
                                    element="input"
                                    validators={[VALIDATOR_NONE()]}
                                    errorText={t("userDetails.askForOrganizationName")}
                                    onInput={InputHandler}
                                    iconName="briefcase"
                                    initialValid={true}
                                    initialValue={profileData.organizationName}
                                    // style={{ margin: '15px auto' }}
                                />
                            ) : null}

                            <button className={classes.loginFormButton} disabled={!state.isValid}>
                                {t("userDetails.saveChanges")}
                            </button>

                            <div className={classes.profileMessage}>{formMessage}</div>
                        </form>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <div className="display-flex-center">
                {" "}
                <Loader type="TailSpin" color="#3aaa35" />{" "}
            </div>
        );
    }
};

export default UserDetails;
