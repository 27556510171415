import React from "react";
import { getImageByKey } from "../../scripts/getImageByKey";
import * as classes from "../ContactUs/ContactUs.module.css";
import { useTranslation } from "react-i18next";

export default function AboutUs() {
    // Setup translate function
    const { t } = useTranslation();

    return (
        <div className={classes.ContactUs}>
            <div className={classes.ContactUsPrimary}>
                <div className={classes.ContactUs_image}>
                    <img src={getImageByKey("aboutus")} alt="Contact Us" />
                </div>
                <div className={classes.ContactUs_description}>
                    <div className={classes.ContactUs_description_Primary}>
                        <h4 className="font-700">{t("footer.aboutus")}</h4>
                        <div className={classes.ContactUs_description_address}>
                            <div className={`${classes.ContactUs_description_address_info} font-400`}>
                                Koiramessu TV -portaali on Suomen Kennelliitto - Finska Kennelklubben ry.:n ylläpitämä ja hallinnoima live-lähetys sivusto
                                osoitteessa: www.koiramessutv.fi. Sivuston teknisestä ylläpidosta ja kehityksestä vastaa Icareus Oy.
                            </div>
                        </div>
                        <div className={classes.ContactUs_description_phone}>
                            <div>
                                {" "}
                                Suomen Kennelliitto – Finska Kennelklubben ry. on vuonna 1889 perustettu Pohjoismaiden vanhin koirajärjestö. Jäsenmäärällä
                                mitattuna olemme yksi maailman suurimmista kennelliitoista. Alan asiantuntijana tuotamme ja jaamme tietoa koira-asioissa. Olemme
                                aktiivisesti vuorovaikutuksessa alan eri sidosryhmien kanssa, vaikutamme koiriin liittyvään lainsäädäntöön sekä ajamme koirien
                                ja koiranomistajien etuja niin kansallisesti kuin kansainvälisestikin.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
