import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useMyContext } from "../../contexts/StateHolder";
import {
    getUser,
    getPackages,
    //getShoppingCategories,
    getPaymentMethods,
} from "../../scripts/dataHandlers";

//shows the pages middle, which view is active and probably some other data.
import StateView from "./StateView/StateView";

//there 3 are different bottom views
import PackagesView from "./Views/PackagesView";
import PaymentMethodsView from "./Views/PaymentMethodsView";
import ReceiptView from "./Views/ReceiptView";

import * as classes from "./PurchaseFlow.module.css";
import * as settings from "../../config_settings.json";

const PurchaseFlow = (props) => {
    const { itemType, itemId, orgId } = useParams();

    console.log("orgId", orgId);

    //this controls which bottom page view is shown
    const [pageState, setPageState] = useState("packages");

    const {
        packages,
        setPackages,

        setSelectedPackage,
        paymentMethods,
        setPaymentMethods,
        user,
        setUser,

        language,
    } = useMyContext();

    const [cookies] = useCookies("");

    const [profileData, setProfileData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        city: "",
        country: "",
        countryId: "",
        address: "",
        postalCode: "",
        regionId: "",
        buyerProducts: [],
    });

    //when coming in as receipt then run this
    useEffect(() => {
        if (itemType === "receipt") {
            setPageState("receipt");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function getUserData() {
            try {
                const response = await getUser(cookies?.userData?.userToken, orgId);

                if (response.status === 200) {
                    const newData = {
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        phone: response.data.phone,
                        email: response.data.emailAddress,
                        city: response.data.city,
                        country: response.data.country,
                        countryId: response.data.countryId,
                        address: response.data.address,
                        postalCode: response.data.postalCode,
                        regionId: response.data.regionId,
                        buyerProducts: response.data.buyerProducts,
                    };
                    setProfileData({ ...newData });
                    if (user.email !== response.data.emailAddress) {
                        let modifiedUser = { ...user };
                        modifiedUser.eMail = response.data.emailAddress;
                        setUser(modifiedUser);
                    }
                } else {
                    console.log("something wrong with request");
                }
            } catch (err) {
                console.log(err);
            }
        }

        if (orgId && orgId > 0 && user && user.userId !== 0) {
            getUserData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, orgId]);

    // sending an api requet to find the package type( ba)
    useEffect(() => {
        async function getPackagesData() {
            try {
                console.log(itemId);
                let fetchItem = itemType === "receipt" ? 0 : itemId;
                const [responseData] = await Promise.all([getPackages(settings.organization.organizationId, language, fetchItem)]);

                let finalData = [];
                if (responseData.status === "ok" && typeof responseData.packages !== "undefined") {
                    finalData = responseData.packages;
                }

                if (finalData.length > 0) {
                    setPackages(finalData);
                }
            } catch (err) {
                console.log(err);
            }
        }

        async function getPaymentMethodsData() {
            try {
                const responseData = await getPaymentMethods(settings.organization.organizationId);

                setPaymentMethods(responseData);
            } catch (err) {
                console.log(err);
            }
        }
        if (orgId && orgId > 0) {
            getPackagesData();
        }

        if (orgId && orgId > 0) {
            getPaymentMethodsData();
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, language]);

    //used when user selects the package
    const selectPackage = (pkg) => {
        console.log("pkg", pkg);
        setPageState("payment");
        setSelectedPackage(pkg);
    };

    //used if user decides to go back to packages view
    const changePageState = (newState) => {
        if (pageState !== newState && pageState !== "receipt") {
            setPageState(newState);
            if (newState !== "receipt") {
                setSelectedPackage({});
            }
        }
    };

    //successful purchase and move to bank url. Doesn't really need to be here. Could move to paymentMethods view.
    const buyPackageSuccess = (newUrl) => {
        window.location.replace(newUrl);
    };

    //used in receipt view or with page refresh. Sets package value to correct one, so it is shown correctly on page
    const setPackageById = (packageId) => {
        if (pageState === "receipt" && packages.length > 0) {
            const pkg = packages.find((pkg2) => pkg2.id === packageId);
            if (typeof pkg !== "undefined") {
                setSelectedPackage(pkg);
            }
        }
    };

    return (
        orgId && (
            <div className={`${classes.packageMain} regular`}>
                <div className={classes.pageState}>
                    <StateView pageState={pageState} changePageState={changePageState} />
                </div>
                {pageState === "packages" && orgId && packages && <PackagesView buyButtonAction={selectPackage} profileData={profileData} orgId={orgId} />}
                {pageState === "payment" && (
                    <PaymentMethodsView
                        paymentMethods={paymentMethods}
                        buyPackageSuccess={buyPackageSuccess}
                        settings={props.settings}
                        changePageState={changePageState}
                    />
                )}
                {pageState === "receipt" && <ReceiptView setPackageById={setPackageById} />}
            </div>
        )
    );
};

export default PurchaseFlow;
