import { useMyContext } from "../../contexts/StateHolder";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

// Renders contentRatings of chosen item
const Login = (props) => {
    // Bring stateholders from context
    const { user, setUser, closeHamMenu, isResponsiveclose } = useMyContext();

    const { t } = useTranslation();
    const history = useHistory();

    const [cookies, removeCookie] = useCookies("");

    const logOut = () => {
        closeHamMenu();

        console.log(cookies);

        setTimeout(() => {
            if (cookies?.userData) {
                removeCookie("userData", 1, { path: "/", expires: new Date(Date.now()) });
            }
            if (cookies?.redirectUrl) {
                removeCookie("redirectUrl", 1, { path: "/", expires: new Date(Date.now()) });
            }
            if (cookies?.ue) {
                removeCookie("ue", 1, { path: "/", expires: new Date(Date.now()) });
            }
            if (cookies?.osec) {
                removeCookie("osec", 1, { path: "/", expires: new Date(Date.now()) });
            }
            setUser({});
            window.location.reload();
        }, 200);

        history.push(`/`);
    };

    return (
        <div className="loginContainer">
            {user.loggedIn ? (
                <>
                    <NavLink className="loginBTN" onClick={closeHamMenu} to={`/${props.routes.profile}`}>
                        {user.loggedIn ? t("login.profileButton") : t("login.profileButton")}
                    </NavLink>
                    {!props.hideLogoutSmallScreen && (
                        <div className="navBarSiteLogoMobile-lgScreen">
                            <div className="" style={{ marginRight: "5px" }}>
                                {"| "}
                            </div>

                            {user?.loggedIn ? (
                                <div onClick={logOut} style={{ color: "white" }}>
                                    {t("login.logOutButton")}
                                </div>
                            ) : (
                                <>
                                    <NavLink className="loginBTN" to={`/${props.routes.login}`}>
                                        {t("login.loginButton")}
                                    </NavLink>
                                </>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <NavLink className="loginBTN" onClick={closeHamMenu} to={props.signUp ? `/${props.routes.signUp}` : `/${props.routes.login}`}>
                    {props.signUp ? t("signUpForm.title") : t("login.loginButton")}
                </NavLink>
            )}
        </div>
    );
};

export default Login;
