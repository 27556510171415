import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { createAssetIdToken, momentDate } from "../../scripts/utils";
import { getImageByKey } from "../../scripts/getImageByKey";
import { useMyContext } from "../../contexts/StateHolder";
import { getAsset } from "../../scripts/dataHandlers";
import * as setting from "../../config_settings.json";
import moment from "moment";

export default function RadiantPlayer(props) {
    console.log("RadiantPlayer props", props, setting.API_Server_Number, 11);

    const rmpPlayer = useRef();

    const { chosenItem, language, key, setChosenItem, assetProperty, user } = useMyContext();

    const history = useHistory();

    const { t } = useTranslation();

    useEffect(() => {
        let radiantscript, vodjs, advertjs, muxjs;

        if (chosenItem && props.isSerie && !chosenItem.serie && props.itemId) {
            // finding ffolder name and id of series asset basically for google analytics
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const fetchChosenItem = async () => {
                const token5 = createAssetIdToken(props.organizationId, props.itemId, language, key);

                const res = await getAsset(props.organizationId, props.itemId, token5, language, assetProperty, user, source);
                console.log(res, "res assets");
                setChosenItem(res);
            };
            fetchChosenItem();
        }

        if (props.secretKey && props.itemId) {
            // console.log(props.secretKey && props.itemId && chosenItem);
            const createScript = () => {
                radiantscript = document.createElement("script");
                vodjs = document.createElement("script");
                advertjs = document.createElement("script");
                muxjs = document.createElement("script");

                vodjs.id = "vodjs";

                radiantscript.src = "https://cdn.radiantmediatechs.com/rmp/7.11.6/js/rmp.min.js";

                muxjs.src = "https://src.litix.io/core/4/mux.js";

                vodjs.src = "https://staging1.icareus.com/lib/js/players/v4/players/vodUpdated.js";
                advertjs.src = "https://staging1.icareus.com/lib/js/players/v3/players/adverts.js";

                vodjs.async = true;
                advertjs.async = true;
                radiantscript.async = true;
                muxjs.async = true;

                document.body.appendChild(radiantscript);
                document.body.appendChild(vodjs);
                document.body.appendChild(advertjs);
                document.body.appendChild(muxjs);
            };
            createScript();

            let serviceOriginState = props.serviceOriginState;
            console.log("LivePlayerEvent serviceOriginState", serviceOriginState);

            let customErrorMessage = t("videoPlayer.customErrorMessage");
            let noSupportMessage = t("videoPlayer.noSupportMessage");
            let noSupportDownload = t("videoPlayer.noSupportDownload");
            let noSupportInstallChrome = t("videoPlayer.noSupportInstallChrome");
            let noSupportInstallChromeLink = t("videoPlayer.noSupportInstallChromeLink");

            // replacements
            // let teamName = "seura";
            let notStreamingMessage = t("videoPlayer.notStreamingMessage");
            // notStreamingMessage = notStreamingMessage.replace("[Seuran nimi]", teamName);
            if (serviceOriginState === 0) {
                noSupportMessage = notStreamingMessage;
                customErrorMessage = notStreamingMessage;
            }

            const labels = {
                error: {
                    customErrorMessage,
                    noSupportMessage,
                    noSupportDownload,
                    noSupportInstallChrome,
                    noSupportInstallChromeLink,
                },
            };

            window._icareus = {};

            window._icareus.companyId = props.companyId;

            window._icareus.groupId = props.groupId;

            window._icareus.organizationId = props.organizationId;

            window._icareus.itemId = props.itemId;

            window._icareus.host = setting.API_Server_Number === 1 ? "https://suite.icareus.com" : "https://suiterc.icareus.com";
            window._icareus.playerId = "rmpPlayer";
            window._icareus.playerType = "radiant";
            window._icareus.playerSetup = "vodJSCallback";
            window._icareus.playerAutoStart = true;
            window._icareus.userId = props.userId;

            // vod adverts stuff start

            window._icareus.campaignId = props.campaignIDD;
            window._icareus.advertVOD = props.campaignIDD ? true : false;
            window._icareus.useAdvertsLibrary = props.campaignIDD ? true : false;

            window._icareus.applicationTypeId = 30;
            window._icareus.applicationId = 133485121; // dont know about this applicationid yet

            // vod adverts end here

            window._icareus.token = createAssetIdToken(props.organizationId, props.itemId, false, props.secretKey);

            // google analytics as instructed by digital starts here only if tracking id provided
            if (chosenItem && props.googleTrackingId) {
                window._icareus.gaLabel = ` ${chosenItem?.name ? chosenItem.name.toUpperCase() : chosenItem.title.toUpperCase()}[${props.itemId}]`;
                window._icareus.gaCategory = props.isSerie ? "Series" : "Videos";
                window._icareus.gatrackingId = props.googleTrackingId;
                window._icareus.isSerie = props.isSerie ? props.isSerie : false;
                window._icareus.gaFolderLabel = props.isSerie
                    ? `${chosenItem.serie?.title}[${chosenItem.serie?.id}]`
                    : chosenItem.folders && chosenItem.folders.length > 0
                    ? `${chosenItem.folders[0].name}/${chosenItem.folders.id}`
                    : "Demo Title For Videos";
                window._icareus.gaFolderCategory = "Folder";
            }
            // google analytics as instructed by digital ends here

            window._icareus.initializedByReactApps = true;

            const backButtonHandler = () => {
                if (props.backRoute === undefined) {
                    history.goBack();
                } else if (props.backRoute === "hidden") {
                    props.setShowPlayer("wantedHidden");
                } else {
                    history.push(props.backRoute);
                }
                //props.backRoute ? history.push(props.backRoute) :
            };

            const backCustomModule = {
                hint: t("Close"),
                svg: getImageByKey("symbolX"),
                svgHover: getImageByKey("symbolX"),
                callback: function () {
                    backButtonHandler();
                },
            };

            let settings;
            // mux data starts here

            // Define Mux Data settings something which was given by usman ->Mux Data uncovers four key dimensions of video quality of service: playback failures, startup time, rebuffering, and video quality. If your aim is broadcast-quality video streaming, Mux Data enables you to monitor these critical video metrics.

            // embedUrl gives us https://salibandytv.icareus.com/web/loviisan-tor/player/embed/event/view?eventId=192393503 so we can extract organization name from it

            let eventDate, eventTime, organizationName;

            if (props?.event) {
                eventDate = momentDate(props?.event?.startTimePublic ? props?.event?.startTimePublic : props?.event?.startTime);

                eventTime = moment(props?.event?.startTimePublic ? props?.event?.startTimePublic : props?.event?.startTime)
                    .locale("fi")
                    .format("LT");

                if (props?.event?.embedUrl) {
                    organizationName = props?.event?.embedUrl.split("/web")[1].split("/player")[0];
                }
                console.log("organizationName", organizationName);
            }
            const muxDataSettings = {
                debug: false,
                data: {
                    env_key: "ltp6paavqcrut41f50gfor608",
                    viewer_user_id: props.userId,

                    // just for our use to know which player we are using . might be different in different webpages

                    player_name: "radiant",

                    // add game/name +team + date + time to Mux data report ->mikkos requirement
                    video_title: props?.event
                        ? `(${props?.event?.name[props?.event?.defaultLanguage]}) ${organizationName}(${props?.event?.groupId - 1}) (${eventDate}-${eventTime})`
                        : "salibandy_game_vod_recording",

                    //https://docs.mux.com/guides/data/make-your-data-actionable-with-metadata
                    // just passing organizationId here to know which org it belongs to

                    sub_property_id: props.organizationId,

                    video_content_type: "recording",
                    video_stream_type: "vod-recording",
                },
            };
            console.log(muxDataSettings, "muxDataSettings");

            // mux data ends here

            window.vodJSCallback = function () {
                settings = {
                    labels: labels,
                    detectViewerLanguage: false,

                    licenseKey: window.location.hostname.includes("icareus.com") ? setting.radiantMediaLicenseIcareus : setting.radiantMediaLicense,
                    src: window._icareus.sources,

                    autoHeightMode: true,
                    autoHeightModeRatio: 1.7777777778,
                    skin: props.skin ? props.skin : "s4",

                    skinBackgroundColor: props.skinBackgroundColor ? props.skinBackgroundColor : "rgba(49, 145, 45, 1)",
                    skinButtonColor: "rgba(255, 221, 0, 1)",
                    skinAccentColor: "rgba(130, 177, 255, 1)",

                    speed: props.speed ? true : false,
                    automaticFullscreenOnLandscape: true,
                    ads: props.campaignIDD ? true : false,

                    adTagUrl: window._icareus.videoVastURL,

                    contentMetadata: {
                        title: props.title ? props.title : null,
                        description: props.description ? props.description : null,
                        poster: [props.poster ? props.poster : window._icareus.thumbnail],
                    },

                    ccFiles: null,
                    ...(props.backButton ? { customModule: [{ ...backCustomModule }] } : {}),

                    // Pass Mux Data settings
                    muxDataSettings: muxDataSettings,

                    // https://www.radiantmediaplayer.com/docs/latest/media-preloading.html  we are changing the preload from smart to auto ->need to test what advantage it brings to the table
                    preload: "auto",

                    asyncElementID: rmpPlayer.current.id,
                };

                // when ready event fires we append our custom overlay div element
                // so that this element is appended on top of other player elements

                window._icareus.playerObject.init({ ...settings });
                // console.log("window", window);
            };
            return () => {
                document.body.removeChild(radiantscript);
                document.body.removeChild(vodjs);
                document.body.removeChild(advertjs);
                document.body.removeChild(muxjs);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div ref={rmpPlayer} id="rmpPlayer"></div>;
}
