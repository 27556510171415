import React, { useCallback, useEffect } from "react";
import LiveVideoPlayer from "../../VideoPlayer/LiveVideoPlayer";
import * as classes from "../Event/UpcomingEvent.module.css";
import EventsRoomDescription from "../Event/EventsRoomDescription";
import { useCookies } from "react-cookie";
import { checkTicketNumber } from "../../../scripts/dataHandlers";

export default function LiveEvents({ routes, ticketAccessGranted, ticketAccessRequired, singleEvent, defaultLanguage, setTicketAccessGranted }) {
    const [cookies, setCookie, removeCookie] = useCookies(["tiAcc"]);

    useEffect(() => {
        if (singleEvent?.eventId) {
            !ticketAccessGranted && checkForVideoAccess();
        }
    }, [singleEvent?.eventId]);
    const checkForVideoAccess = useCallback(async () => {
        if (singleEvent?.accessControl?.length > 0) {
            // this is paid event
            const res = await checkTicketNumber(singleEvent?.eventId, cookies?.tiAcc);

            if (res.data.status === "ok") {
                setCookie("tiAcc", cookies?.tiAcc, {
                    path: "/",
                });
                setTicketAccessGranted(true);
            } else {
                removeCookie("tiAcc", { path: "/" });
                setTicketAccessGranted(false);
            }
        }
    }, []);

    return (
        <>
            {((cookies?.tiAcc && ticketAccessRequired && ticketAccessGranted) || !ticketAccessRequired) && (
                <div className={classes.SecondaryContainer}>
                    <LiveVideoPlayer
                        channelServiceId={singleEvent?.isMultiroom ? singleEvent.rooms[0].serviceId : singleEvent.serviceId}
                        eventPlayer={true}
                        eventId={singleEvent.eventId}
                        event={singleEvent}
                    />
                </div>
            )}

            <EventsRoomDescription
                liveEvent={true}
                routes={routes}
                ticketAccessGranted={ticketAccessGranted}
                singleEvent={singleEvent}
                ticketAccess={ticketAccessRequired}
                defaultLanguage={defaultLanguage}
                setTicketAccessGranted={setTicketAccessGranted}
            />
        </>
    );
}
