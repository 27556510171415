import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import PaymentMethod from "../PaymentMethod/PaymentMethod";
import SelectedPackage from "../SelectedPackage/SelectedPackage";
import VoucherView from "../VoucherView/VoucherView";
import { getPaymentUrl } from "../../../scripts/dataHandlers";
import { useMyContext } from "../../../contexts/StateHolder";
import * as classes from "./PaymentMethodsView.module.css";
import * as classes1 from "../../Paytrail/Paytrail.module.css";
import Loader from "react-loader-spinner";
import Paytrail from "../../Paytrail/Paytrail";

//Does a lot. Shows either paymentMethods or voucher based on users possible payment methods
//if user selected voucher then the voucher view will handle those changes
const PaymentMethodsView = (props) => {
    const { paymentMethods, buyPackageSuccess } = props;
    console.log("aymentMethods", paymentMethods);

    const {
        user,
        organizationId,
        selectedPackage,
        userEmail,

        ticketQuantity,
        setTicketQuantity,
        loading,
        setLoading,
    } = useMyContext();

    const [cookies] = useCookies("ea");

    const history = useHistory();
    const [formRenderedPaytrail, setFormRenderedPaytrail] = useState(false);

    const [paytrailResponse, setPaytrailResponse] = useState(null);

    const [mobilePaymentResponse, setmobilePaymentResponse] = useState(null);
    const [cardPaymentResponse, setCardPaymentResponse] = useState(null);
    const [bankPaymentResponse, setBankPaymentResponse] = useState(null);

    const [voucherView, setVoucherView] = useState(null);
    const [paymentMethodError, setPaymentMethodError] = useState(null);

    const { t } = useTranslation();
    const { itemType, itemId } = useParams();
    console.log(`itemType`, itemType, itemId);

    const buyPackage = async (methodId, packageId) => {
        setLoading(true);
        let handlerUrl = window.location.protocol + "//" + window.location.hostname;
        handlerUrl += window.location.hostname.includes("localhost") ? ":" + window.location.port : "";
        handlerUrl += props.settings.returnUrlPath;
        handlerUrl = handlerUrl + `${organizationId}/${itemId}`;
        console.log(`cookies`, cookies);

        try {
            const paymentUrl = await getPaymentUrl(cookies?.userData?.userToken, organizationId, packageId, methodId, cookies?.ue, handlerUrl, ticketQuantity);

            // handlerUrl is return URl in sucess
            console.log("PP: ", paymentUrl);
            setTicketQuantity(1);
            setLoading(false);
            setmobilePaymentResponse(paymentUrl.providers.filter((el) => el.group === "mobile"));
            setCardPaymentResponse(paymentUrl.providers.filter((el) => el.group === "creditcard"));
            setBankPaymentResponse(paymentUrl.providers.filter((el) => el.group === "bank"));
            setPaytrailResponse(paymentUrl);
            setFormRenderedPaytrail(true);
        } catch (err) {
            console.log(err);
        }
    };

    const buyFunction = (methodId, methodKey) => {
        if (Object.keys(selectedPackage).length === 0) {
            console.log("Can't buy without package");
            return;
        }
        if (methodKey === "voucher") {
            setVoucherView("active");
        } else {
            buyPackage(methodId, selectedPackage.id);
        }
    };

    const hideVoucher = () => {
        setVoucherView(null);
    };

    const moveToReceipt = (voucherActivated) => {
        setVoucherView(null);
        history.push(`/event/${organizationId}/${itemId}?voucheractivated=true`);
        //changePageState("receipt");
        console.log("move to receipt view");
    };

    return (
        <div className={classes.payment}>
            <div className={`${classes.paymentTitle} font-500`}>{t("packages.PackagePayment")}</div>
            <SelectedPackage />

            {selectedPackage && !formRenderedPaytrail && !paytrailResponse && (
                <div className={classes.paymentMethodsContainer}>
                    <div key="title" className={`${classes.paymentMethodsTitle} font-500`}>
                        {t("packages.PackagePaymentMethods")}
                    </div>
                    <div className={`${classes.paymentMethodHelp} font-300`}>{t("packages.PackagePaymentHelp")}</div>
                    {loading ? (
                        <div className="display-flex-center">
                            <Loader type="TailSpin" color="#3aaa35" />{" "}
                        </div>
                    ) : (
                        <>
                            {paymentMethodError && <div className={classes.paymentMethodError}>{paymentMethodError}</div>}
                            {voucherView === null ? (
                                <div className={classes.paymentMethods}>
                                    {Object.values(paymentMethods).map((value) => (
                                        <PaymentMethod key={value.id} paymentMethod={value} buyFunction={buyFunction} />
                                    ))}
                                </div>
                            ) : (
                                <div className={classes.paymentMethods_Voucher}>
                                    <VoucherView hideVoucher={hideVoucher} moveToReceipt={moveToReceipt} selectedPackage={selectedPackage} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}

            {formRenderedPaytrail && paytrailResponse && (
                <>
                    <Paytrail
                        bankPaymentResponse={bankPaymentResponse}
                        mobilePaymentResponse={mobilePaymentResponse}
                        cardPaymentResponse={cardPaymentResponse}
                        paytrailResponse={paytrailResponse}
                    />
                </>
            )}
        </div>
    );
};

export default PaymentMethodsView;
