import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import StateHolder from "./contexts/StateHolder";

import "./i18n/config";
import { CookiesProvider } from "react-cookie";
import ScrollToTop from "./components/ScrollToTop";
import ContextFunctions from "./contexts/ContextFunctions";

if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
}

ReactDOM.render(
    <Router>
        <CookiesProvider>
            <StateHolder>
                <ContextFunctions>
                    <ScrollToTop />
                    <App />
                </ContextFunctions>
            </StateHolder>
        </CookiesProvider>
    </Router>,
    document.getElementById("root")
);
