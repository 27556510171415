import React from 'react';
import Events from '../../Events/Events/Events';
import classes from './ChannelEvents.module.css';

export default function ChannelEvents(props) {
  console.log(props);
  // secondary classname means there will be at least 3 images when the width of screen is aprrox 1400px(80% of container)
  return (
    <div className={classes.ChannelEventsPrimaryContainer}>
      <Events
        organizationId={props.organizationId}
        secondaryClassname={true}
        hideEditorChoiceEvent={true}
        settings={props?.settings}
      />
    </div>
  );
}
