import { useHistory, Link } from "react-router-dom";
import { getImageByKey } from "../../scripts/getImageByKey";
import { useTranslation } from "react-i18next";
import * as classes from "./Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BottomBar2 = (props) => {
    const history = useHistory();

    const { t } = useTranslation();

    return (
        <>
            {" "}
            <div className={classes.FooterPartner}>
                <h2 style={{ textAlign: "center", color: "#666", marginBottom: 0 }} className="font-600">
                    {" "}
                    Pääyhteistyökumppanit
                </h2>
                <div className={classes.FooterMainPartner}>
                    <div className={classes.FooterPartnerWrapper}>
                        <a href="https://www.royalcanin.com/fi" target="_blank" rel="noopener noreferrer">
                            <img src={getImageByKey("footerImage1")} alt="" style={{ height: "74px", width: "211px", minWidth: "211px" }} />
                        </a>
                        <a href="https://www.agria.fi/" target="_blank" rel="noopener noreferrer">
                            <img
                                src={getImageByKey("footerImage8")}
                                alt=""
                                style={{ height: "57px", width: "139px", minWidth: "139px" }}
                                className={classes.FooterPartner_img8}
                            />
                        </a>
                    </div>
                </div>
                <div className={classes.FooterOtherPartner}>
                    <div className={classes.FooterPartnerWrapper}>
                        <a
                            href="https://www.kennelliitto.fi/liity-jaseneksi/koiramme-lehti"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ height: "30px", width: "162px", minWidth: "162px" }}
                        >
                            {" "}
                            <img src={getImageByKey("footerImage5")} alt="" />
                        </a>
                        <a
                            href="https://www.kennelliitto.fi/yhteystiedot/showlink-ja-prettybit-projects"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ height: "56px", width: "159px", minWidth: "159px" }}
                        >
                            <img src={getImageByKey("footerImage7")} alt="" />
                        </a>
                        <a
                            href="https://www.showlink.fi/show/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ height: "41px", width: "137px", minWidth: "137px" }}
                        >
                            <img src={getImageByKey("footerImage10")} alt="" />
                        </a>
                        <a href="https://evidensia.fi/" target="_blank" rel="noopener noreferrer" style={{ height: "23px", width: "220px", minWidth: "220px" }}>
                            <img src={getImageByKey("footerImage2")} alt="" />
                        </a>
                        <a
                            href="https://www.scandichotels.fi/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ height: "31px", width: "140px", minWidth: "140px" }}
                        >
                            <img src={getImageByKey("footerImage9")} alt="" />
                        </a>
                    </div>
                </div>
                <div className={classes.FooterOtherLogo}>
                    <div className={classes.FooterPartnerWrapper}>
                        <div className={classes.FooterOtherLogo_LogoAndText}>
                            <img src={getImageByKey("footerImage4")} alt="" />
                            <p>© Suomen Kennelliitto - Finska Kennelklubben ry. Kamreerintie 8, 02770 Espoo, 09 887 300</p>
                        </div>
                        <div className={classes.FooterOtherLogo_Logo}>
                            <img src={getImageByKey("footerImage3")} alt="" />
                        </div>
                        <div className={classes.FooterOtherLogo_Logo}>
                            <img src={getImageByKey("footerImage6")} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.Footer} font-200`}>
                <div className={`${classes.FooterPrimary} font-200`}>
                    <div className={classes.Footer_Description}>
                        <div className={classes.Footer_Description_Heading}>
                            <img
                                className="navBarSiteLogo"
                                src={getImageByKey("organizationLogo")}
                                title={t("Back to Home")}
                                alt="SiteName"
                                onClick={() => history.push("/")}
                            />
                        </div>
                        <div className={classes.Footer_Description_Heading_Info}>
                            <br />
                            Copyright © {new Date().getFullYear()} - Kennelliitto
                        </div>
                    </div>
                    <div className={classes.Footer_Menu}>
                        <div className={classes.Footer_Menu_Heading}>{t("footer.menu")}</div>

                        <div className={classes.Footer_Menu_info}>
                            <div className={classes.Footer_Menu_Links}>
                                <Link to={`/${props.routes.aboutus}`}>{t("footer.aboutus")}</Link>
                            </div>
                            <div className={classes.Footer_Menu_Links}>
                                <Link to={`/${props.routes.tos}`}>{t("footer.termsAndCondition")}</Link>
                            </div>
                            <div className={classes.Footer_Menu_Links}>
                                <Link to={`/${props.routes.privacyPolicy}`}>{t("footer.privacyterms")}</Link>
                            </div>
                            <div className={classes.Footer_Menu_Links}>
                                <Link to={`/${props.routes.contactus}`}>{t("footer.contactUs")}</Link>
                            </div>
                        </div>
                    </div>
                    <div className={classes.Footer_SocialMedia}>
                        <div className={classes.Footer_SocialMedia_Heading}>{t("footer.socialMedia")} </div>
                        <div className={classes.Footer_SocialMedia_Heading_Info}>
                            <div className={classes.Footer_SocialMedia_Links}>
                                <FontAwesomeIcon icon={["fab", "facebook"]} className={classes.Footer_SocialMedia_Links_icons} />{" "}
                                <a href="https://www.facebook.com/koiramessut" target="_blank" rel="noopener noreferrer">
                                    Facebook{" "}
                                </a>
                            </div>

                            {/* <div className={classes.Footer_SocialMedia_Links}>
                                <FontAwesomeIcon icon={["fab", "twitter"]} className={classes.Footer_SocialMedia_Links_icons} />
                                <a href="" target="_blank" rel="noopener noreferrer">
                                    Twitter{" "}
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BottomBar2;
