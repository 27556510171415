import React, { useContext } from "react";
import { useCookies } from "react-cookie";
import { useMyContext } from "../contexts/StateHolder";
import { useHistory } from "react-router-dom";

import { authenticateUser, registerUser } from "../scripts/dataHandlers";

// First create the context to hold shared functions
const MyContextFunctions = React.createContext();

// Then create a ContextFunctions wrapper component
// to hold the shared functions that the components need.
const ContextFunctions = (props) => {
    // Bring stateholders from another context
    const { organizationId, setUser, setGoBackToPrevious } = useMyContext();

    const [cookies, setCookie] = useCookies("");
    const history = useHistory();

    const authProcess = async (email, password) => {
        try {
            const authResponse = await authenticateUser(organizationId, email, password);

            if (authResponse.data.status === "error") {
                // Give error message to user
            } else if (authResponse.data.status === "ok") {
                let newUser = {
                    userId: authResponse.data.user_id,
                    userToken: authResponse.data.user_token,
                    firstName: authResponse.data.user_name,
                    eMail: email,
                    loggedIn: true,
                    organizationId: authResponse.data.user_organization_id,
                    organizationName: authResponse.data.user_organization_name,
                };

                // Update user state
                setUser(newUser);
                console.log(newUser);

                // Set userToken in cookies
                setCookie(
                    "userData",
                    {
                        userId: authResponse.data.user_id,
                        userToken: authResponse.data.user_token,
                        userOrganizationId: authResponse.data.user_organization_id,

                        firstName: authResponse.data.user_name,
                        userOrganizationName: authResponse.data.user_organization_name,
                    },
                    {
                        path: "/",
                    }
                );
                setCookie("ue", email, {
                    path: "/",
                });
            }

            console.log(authResponse);
            return authResponse;
        } catch (err) {
            console.log(err);
            console.log(cookies);
        }
    };

    const registerProcess = async (inputs) => {
        try {
            const response = await registerUser(organizationId, inputs);

            return response;
        } catch (err) {
            console.log(err);
        }
    };
    const redirectUserToLogin = async (loginRoute) => {
        const currentPath = window.location.pathname;
        console.log(currentPath);

        // If user isnt already on loginRoute
        if (currentPath !== loginRoute) {
            console.log(currentPath);
            // Set current route in context
            setGoBackToPrevious(true);
            // Push user to defined loginRoute
            history.push(`/${loginRoute}`);
        }
    };

    return (
        <MyContextFunctions.Provider
            value={{
                authProcess,

                registerProcess,
                redirectUserToLogin,
            }}
        >
            {props.children}
        </MyContextFunctions.Provider>
    );
};

export const useMyContextFunctions = () => useContext(MyContextFunctions);

export default ContextFunctions;
