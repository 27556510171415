import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { createAssetIdToken, momentDate } from "../../scripts/utils";
import * as classes from "./RadiantPlayer.module.css";
import { useTranslation } from "react-i18next";
import { getImageByKey } from "../../scripts/getImageByKey";

import * as setting from "../../config_settings.json";
import moment from "moment";
import Loader from "react-loader-spinner";

export default function LiveEventPlayer(props) {
    console.log("LivePlayerEvent props", props);
    const [loading, setLoading] = useState(true);

    const rmpPlayer = useRef();

    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.channelServiceId) {
            let radiantscript, liveScript, eventScript, muxjs;

            // no need vod.js for live event/video
            // need to load both live.js and event script for events which are  live
            const createScript = () => {
                radiantscript = document.createElement("script");
                liveScript = document.createElement("script");
                eventScript = document.createElement("script");
                // external mux script
                muxjs = document.createElement("script");

                radiantscript.src = "https://cdn.radiantmediatechs.com/rmp/7.11.6/js/rmp.min.js";

                muxjs.src = "https://src.litix.io/core/4/mux.js";

                liveScript.src = "https://staging1.icareus.com/lib/js/players/v3/players/live.js";
                eventScript.src = "https://staging1.icareus.com/lib/js/players/v1/players/events.js";

                liveScript.id = "livejs";
                radiantscript.id = "radiantLive";
                eventScript.id = "eventLive";
                muxjs.id = "muxLive";

                eventScript.async = true;
                liveScript.async = true;
                radiantscript.async = true;
                muxjs.async = true;

                document.body.appendChild(radiantscript);
                document.body.appendChild(liveScript);
                document.body.appendChild(muxjs);

                props.eventPlayer && document.body.appendChild(eventScript);
            };
            createScript();

            let serviceOriginState = props.serviceOriginState;

            let customErrorMessage = t("videoPlayer.customErrorMessage");
            let noSupportMessage = t("videoPlayer.noSupportMessage");
            let noSupportDownload = t("videoPlayer.noSupportDownload");
            let noSupportInstallChrome = t("videoPlayer.noSupportInstallChrome");
            let noSupportInstallChromeLink = t("videoPlayer.noSupportInstallChromeLink");

            let notStreamingMessage = t("videoPlayer.notStreamingMessage");

            if (serviceOriginState === 0) {
                noSupportMessage = notStreamingMessage;
                customErrorMessage = notStreamingMessage;
            }

            const labels = {
                error: {
                    customErrorMessage,
                    noSupportMessage,
                    noSupportDownload,
                    noSupportInstallChrome,
                    noSupportInstallChromeLink,
                },
            };

            window._icareus = {};

            window._icareus.companyId = props.companyId;

            window._icareus.groupId = props.groupId;

            window._icareus.organizationId = props.organizationId;
            // itemID and serviceId is same and it must be passed
            window._icareus.itemId = props.channelServiceId;
            window._icareus.serviceId = props.channelServiceId;
            window._icareus.host = setting.API_Server_Number === 1 ? "https://suite.icareus.com" : "https://suiterc.icareus.com";

            window._icareus.playerId = "rmpLivePlayer";
            window._icareus.playerType = "radiant";
            window._icareus.playerSetup = "startOnDemandPlayer";
            window._icareus.playerAutoStart = true;
            window._icareus.userId = props.userId;
            window._icareus.licenseFileUrl = "//icareus-cache.secure2.footprint.net/suite/radiantplayerlicenses.json";
            window._icareus.streamRootKeysUrl = "//icareus-cache.secure2.footprint.net/suite/streamrootkeys.json";

            window._icareus.token = createAssetIdToken(props.organizationId, props.channelServiceId, false, props.token);

            const backButtonHandler = () => {
                props.backRoute ? history.push(props.backRoute) : history.goBack();
            };

            // const customModule = [backCustomModule];
            const customModule = [];

            let settings;
            function functionOne(_callback) {
                if (!props.eventPlayer) return;
                window._icareus.eventIdActual = props.eventId;
                // assetID should always be 0 wheen it is live event
                window._icareus.assetId = 0;
                window.EVENT_ANALYTICS.init();
                _callback();
            }

            // mux data starts here

            // Define Mux Data settings something which was given by usman ->Mux Data uncovers four key dimensions of video quality of service: playback failures, startup time, rebuffering, and video quality. If your aim is broadcast-quality video streaming, Mux Data enables you to monitor these critical video metrics.

            // embedUrl gives us https://salibandytv.icareus.com/web/loviisan-tor/player/embed/event/view?eventId=192393503 so we can extract organization name from it

            let eventDate, eventTime, organizationName;

            if (props?.event) {
                eventDate = momentDate(props?.event?.startTimePublic ? props?.event?.startTimePublic : props?.event?.startTime);

                eventTime = moment(props?.event?.startTimePublic ? props?.event?.startTimePublic : props?.event?.startTime)
                    .locale("fi")
                    .format("LT");

                if (props?.event?.embedUrl) {
                    organizationName = props?.event?.embedUrl.split("/web")[1].split("/player")[0];
                }
                console.log("organizationName", organizationName);
            }

            const muxDataSettings = {
                debug: false,
                data: {
                    env_key: "ltp6paavqcrut41f50gfor608",
                    viewer_user_id: props.userId,

                    // just for our use to know which player we are using . might be different in different webpages

                    player_name: "radiant",

                    // add game/name +team + date + time to Mux data report ->mikkos requirement
                    video_title: props?.event
                        ? `(${props?.event?.name[props?.event?.defaultLanguage]}) ${organizationName}(${props?.event?.groupId - 1}) (${eventDate}-${eventTime})`
                        : "salibandy_game_live",

                    //https://docs.mux.com/guides/data/make-your-data-actionable-with-metadata
                    // just passing organizationId here to know which org it belongs to

                    sub_property_id: props.organizationId,

                    video_content_type: "event",
                    video_stream_type: "live",
                },
            };

            // please pass the param maxMaxBufferLength and set it to 120 (default is 600) ->usman comments

            // more info https://www.radiantmediaplayer.com/docs/latest/hls-streaming.html
            // https://github.com/video-dev/hls.js/blob/master/docs/API.md#fine-tuning

            const hlsJSCustomConfig = {
                maxMaxBufferLength: 60,
            };
            console.log(muxDataSettings, "muxDataSettings");

            // mux data ends here

            window.startOnDemandPlayer = function () {
                settings = {
                    licenseKey: window.location.hostname.includes("icareus.com") ? setting.radiantMediaLicenseIcareus : setting.radiantMediaLicense,
                    src: window._icareus.sources,

                    autoHeightMode: true,
                    autoHeightModeRatio: 1.7777777778,
                    skin: props.skin ? props.skin : "s4",

                    skinBackgroundColor: props.skinBackgroundColor ? props.skinBackgroundColor : "rgba(49, 145, 45, 1)",
                    skinButtonColor: "rgba(255, 221, 0, 1)",
                    skinAccentColor: "rgba(130, 177, 255, 1)",

                    speed: props.speed ? true : false,
                    automaticFullscreenOnLandscape: true,

                    contentMetadata: {
                        title: props.title ? props.title : null,
                        description: props.description ? props.description : null,
                        poster: [props.poster ? props.poster : window._icareus.thumbnail],
                    },
                    // custom message to player
                    labels: labels,
                    detectViewerLanguage: false,
                    // end of custom message

                    customModule: customModule,

                    // Pass Mux Data settings
                    muxDataSettings: muxDataSettings,

                    // custom data for hls ->given by usman

                    hlsJSCustomConfig: hlsJSCustomConfig,

                    // https://www.radiantmediaplayer.com/docs/latest/media-preloading.html  we are changing the preload from smart to auto ->need to test what advantage it brings to the table
                    preload: "auto",

                    asyncElementID: rmpPlayer?.current.id,
                };

                window._icareus.playerObject.init(settings);

                console.log("window", window);

                // we need to send event analytics after we have sent live analytics so this functionOne should be invoked at the end

                props.eventPlayer && functionOne(() => {});
            };
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            return () => {
                let f = document.getElementById("livejs");
                f && document?.body?.removeChild(f);
                let f1 = document.getElementById("radiantLive");
                f1 && document?.body?.removeChild(f1);
                let f2 = document.getElementById("eventLive");
                f2 && document?.body?.removeChild(f2);
                let f3 = document.getElementById("muxLive");
                f3 && document?.body?.removeChild(f3);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.channelServiceId]);

    useEffect(() => {
        if (props.channelServiceId) {
            let f = document.getElementById("livejs");
            f && document?.body?.removeChild(f);
            let f1 = document.getElementById("radiantLive");
            f1 && document?.body?.removeChild(f1);
            let f2 = document.getElementById("eventLive");
            f2 && document?.body?.removeChild(f2);
            let f3 = document.getElementById("muxLive");
            f3 && document?.body?.removeChild(f3);
        }
    }, [props.channelServiceId]);

    return (
        <>
            <div className={classes.RadiantPlayer}>
                <div ref={rmpPlayer} id="rmpLivePlayer"></div>
            </div>
            {loading && (
                <div className="display-flex-center">
                    <Loader type="TailSpin" color="#3aaa35" />
                </div>
            )}
        </>
    );
}
