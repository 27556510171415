export default function RenderBanner({ item, clickItem, styles }) {
  return (
    <img
      className='bannerItem'
      key={item.id}
      onClick={() => clickItem(item)}
      src={item.bannerImageSmall}
      alt=''
      style={styles?.bannerItem}
    />
  );
}
