import React, { useCallback, useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useMyContext } from "../../../contexts/StateHolder";

import { useParams } from "react-router-dom";
import { getPackages, getSingleEvent, getUser, purchasePackage } from "../../../scripts/dataHandlers";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import queryString from "query-string";
import { ToastContainer, toast } from "react-toastify";
import "moment/locale/fi";
import { useLocation } from "react-router-dom";
import CompletedEvents from "./CompletedEvents";
import FutureEvents from "./FutureEvent";
import * as settings from "../../../config_settings.json";

export default function UpcomingEvent(props) {
    const [singleEvent, setSingleEvent] = useState(null);
    const [defaultLanguage, setDefaultLanguage] = useState(null);
    const [loading, setLoading] = useState(true);

    const { singleEventItem, setTicketQuantity, profilePackages, setUser, setProfilePackages, language } = useMyContext();
    const { orgId, eventId } = useParams();
    const { user, setGoBackToPrevious } = useMyContext();

    // if the events requires ticket to grant access

    const [ticketAccessRequired, setTicketAccessRequired] = useState(false);
    const [ticketAccessGranted, setTicketAccessGranted] = useState(false);
    const [cookies, setCookie] = useCookies(["tiAcc"]);
    const history = useHistory();
    const location = useLocation();
    const [organizationPackageFetching, setOrganizationPackageFetching] = useState(true);

    useEffect(() => {
        // we need to check for login if it does not exists we will redirect to home page
        console.log(user, 1234);

        if (!user?.loggedIn) {
            console.log("location", location.pathname);
            // lets save this location to cookies

            setCookie("redirectUrl", location.pathname, {
                path: "/",
            });
            setGoBackToPrevious(true);
            history.replace("/login");
        }
    }, [user]);

    const getPackagesData = useCallback(async () => {
        try {
            const responseData = await getPackages(settings.organization.organizationId, language, 0);

            console.log(`packagesData`, responseData);

            if (responseData.status === "ok" && typeof responseData.packages !== "undefined") {
                console.log("set progilePack");
                setProfilePackages(responseData.packages);
            }
        } catch (err) {
            console.log(err);
        }
    }, []);

    const getUserData = useCallback(async function getUserData() {
        try {
            let modifiedUser = { ...user };
            console.log(user, 123);

            const response = await getUser(cookies?.userData?.userToken, user.organizationId);
            console.log("products:", response.data);

            if (response?.data?.buyerProducts) {
                modifiedUser.buyerProducts = response.data.buyerProducts;

                setUser(modifiedUser);
            }
        } catch (err) {
            console.log(err);
        }
    }, []);

    // if ticccket has been checked already so we can give error /succcesful message

    // this useEffect will only run when user is redirected from purchase page to here
    useEffect(() => {
        let params = queryString.parse(window.location.search);

        async function purchasePackageData() {
            const purchaseResponse = await purchasePackage(cookies?.userData?.userToken, params);
            console.log(purchaseResponse, "purchaseResponse");
            let ticket;

            console.log(purchaseResponse, purchaseResponse.message, purchaseResponse.errorCode === 27, purchaseResponse.errorCode);
            if (purchaseResponse.message.includes("successful") || purchaseResponse.status === "ok") {
                setTicketAccessGranted(true);

                // if we have just bught a package then api does not give ticket info

                if (purchaseResponse?.tickets) {
                    //   we have just bought a ticket
                    ticket = purchaseResponse?.tickets[0];
                    console.log("ticketnew", ticket);

                    ticket && setCookie("tiAcc", ticket, { path: "/" });
                } else {
                    // we just bouht a package
                    setTicketAccessRequired(false);
                }

                toast.success("Purchase Successful");
            } else if (
                purchaseResponse.errorCode === 27 ||
                purchaseResponse.message.includes("refresh call for order")

                // this occours when user refrsh the page
            ) {
                setTicketAccessGranted(true);

                if (purchaseResponse?.tickets) {
                    ticket = purchaseResponse?.tickets[0];
                    console.log("ticketrefreh", ticket);

                    ticket && setCookie("tiAcc", ticket, { path: "/" });
                } else {
                    // we just bouht a package
                    setTicketAccessRequired(false);
                }
            }

            setLoading(false);
        }

        // for dpo payment
        if (params) {
            if (params?.paymentMethod === "7") {
                purchasePackageData();
            } else if (params?.RETURN_CODE === "0") {
                purchasePackageData();
            } else if (params?.method === "paytrail" && params["checkout-status"] === "ok") {
                // iuiu
                purchasePackageData();
            } else if (params.voucheractivated) {
                console.log("params123", params);
                // here we have to profile fetching again and this will check for voucher
                setTimeout(() => {
                    setOrganizationPackageFetching(true);
                }, 200);
                setTimeout(() => {
                    const fetchUserInfo = async () => {
                        await getPackagesData();
                        await getUserData();
                        setOrganizationPackageFetching(false);
                    };
                    fetchUserInfo();
                }, 200);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // checks ticket
    console.log("organizationPackageFetching", organizationPackageFetching);
    useEffect(() => {
        if (!organizationPackageFetching) {
            const checkTicket = (eventObj) => {
                setSingleEvent(eventObj);
                setDefaultLanguage(eventObj.defaultLanguage || "en_US");

                // first get all sku from user buyer product

                let userAllSKU = user?.buyerProducts?.map((el) => el.sku);

                // get all configurration from organization

                let allEventInPackage = profilePackages.map((el) => el.configuration)?.filter((el1) => el1 !== "");

                // if eventId is included in allEventInPackag then it is in package

                if (allEventInPackage.includes(eventId)) {
                    // event is included in some kind of packages

                    // now filtering out sku which belings to current event

                    let divaripackageSKU = profilePackages
                        .filter((el) => el?.configuration.includes(eventId))

                        .map((el1) => el1.sku);
                    // console.log(divaripackageSKU, userAllSKU);

                    // let f = divaripackageSKU.some((el) => el.includes(userAllSKU));
                    // console.log(userAllSKU.some((el) => divaripackageSKU.includes(el)));

                    // if userAllSKu is not included in divariPackage it means user have not bought the product yet
                    if (!userAllSKU?.some((el) => divaripackageSKU?.includes(el))) {
                        // user have not bought this
                        setTicketAccessRequired(true);
                        setTicketAccessGranted(false);
                    } else {
                        // user have bought this ->also but ticketAccess required as false so there in no ticket number check
                        setTicketAccessRequired(false);
                        setTicketAccessGranted(true);
                    }
                } else if (eventObj?.accessControls?.length > 0) {
                    const ticketExists = eventObj?.accessControls.find((el) => el.typeName === "Ticket");
                    console.log("ticketExists", ticketExists);
                    if (ticketExists) {
                        setTicketAccessRequired(true);
                    } else {
                        setTicketAccessRequired(false);

                        setTicketAccessGranted(true);
                    }
                } else {
                    setTicketAccessRequired(false);
                    setTicketAccessGranted(true);
                }
                setTimeout(() => {
                    setLoading(false);
                }, 100);
            };

            if (singleEventItem) {
                console.log(user);

                setSingleEvent(singleEventItem);
                checkTicket(singleEventItem);
            } else {
                const getData = async () => {
                    const res = await getSingleEvent(orgId, eventId);

                    console.log("res", res);
                    console.log(user);

                    checkTicket(res?.event);
                };
                getData();
            }
            return () => {
                setTicketQuantity(1);
            };
        }
    }, [singleEventItem, eventId, orgId, organizationPackageFetching]);

    useEffect(() => {
        if (user?.userToken) {
            const fetchInfo = async () => {
                (!profilePackages || profilePackages.length === 0) && (await getPackagesData());
                !user?.buyerProducts && (await getUserData());
                setTimeout(() => {
                    setOrganizationPackageFetching(false);
                }, 10);
            };

            fetchInfo();
        }
    }, [user?.userToken]);

    const Renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return !loading && singleEvent ? (
                <CompletedEvents
                    routes={props.routes}
                    singleEvent={singleEvent}
                    ticketAccessRequired={ticketAccessRequired}
                    setTicketAccessGranted={setTicketAccessGranted}
                    ticketAccessGranted={ticketAccessGranted}
                    defaultLanguage={defaultLanguage}
                />
            ) : (
                <></>
            );
        } else {
            return (
                <>
                    <FutureEvents
                        days={days}
                        hours={hours}
                        minutes={minutes}
                        seconds={seconds}
                        completed={completed}
                        routes={props.routes}
                        singleEvent={singleEvent}
                        defaultLanguage={defaultLanguage}
                        setTicketAccessGranted={setTicketAccessGranted}
                        ticketAccessGranted={ticketAccessGranted}
                        ticketAccessRequired={ticketAccessRequired}
                    />
                </>
            );
        }
    };

    return (
        <>
            <ToastContainer />
            <div>
                {!loading && singleEvent && (
                    <>
                        <Countdown date={singleEvent?.startTime} renderer={Renderer} />
                    </>
                )}
            </div>
        </>
    );
}
